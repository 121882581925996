import * as React from "react"
import withLayout from "../hoc/withLayout";
import { Container } from "react-bootstrap";

const PolicyPage = () => {
  return (
  <main className="my-5">
    <Container>
      <h1>Политика конфиденциальности</h1>

      <p>
        Данная страница содержит сведения о том, какую информацию мы получаем и собираем, когда Вы пользуетесь Сайтом. 
        Надеемся, что эти сведения помогут Вам принимать осознанные решения в отношении предоставляемой нам личной информации.
      </p>

      <p>
        Администрация сайта helpdpf.ru (далее Сайт) с уважением относится к правам посетителей Сайта и 
        безоговорочно признаёт важность конфиденциальности их личной информации. 
        Настоящая Политика конфиденциальности распространяется только на Сайт и на информацию, собираемую им и посредством его. 
        Она не распространяется ни на какие другие веб-сайты и не применима к веб-сайтам третьих лиц, с которых могут ссылаться на Сайт.
      </p>

      <h2>Сбор информации</h2>
      
      <p>
        При посещении Сайта автоматически определяется имя домена Вашего провайдера, 
        страна и некоторые переходы с одной страницы на другую. 
        Получаемые сведения могут быть использованы для того, чтобы облегчить пользование Сайтом, включая, но не ограничиваясь:
      </p>

      <ul>
        <li>организацию Сайта наиболее удобным для пользователей способом;</li>
        <li>предоставление возможности подписаться на почтовую рассылку по специальным предложениям и темам, если Вы хотите получать такие уведомления;</li>
      </ul>

      <p>
        На сервере хранится Ваша личная информация, которую Вы предоставляете добровольно при посещении Сайта. 
        Понятие «личная информация» включает информацию, которая определяет Вас как конкретное лицо, например, Ваше имя, адрес электронной почты или номер телефона. 
      </p>

      <p>
        Сайт использует cookies для для того чтобы определить Ваши настройки, а также для создания статистической отчётности. 
        Cookies – это небольшой фрагмент данных, отправленный Сайтом и хранимый на Вашем компьютере.
        В cookies содержится информация, которая может быть необходима для Сайта — для сохранения Ваших персонализированных настроек и 
        сбора статистической информации по Сайту, т. е. какие страницы Вы посетили, что было загружено, имя домена интернет-провайдера и страна посетителя,
        а также адреса сторонних веб-сайтов, с которых совершён переход на Сайт и так далее. 
        Однако вся эта информация никак не связана с Вами как с личностью. В сookies не записывается Ваш адрес электронной почты и 
        какие-либо личные сведения относительно Вас. 
        Cookies также используют счётчики сторонних компаний (LiveInternet, Яндекс.Метрика, Google Analytics и т. п.), которые могут быть установлены на Сайте.
      </p>

      <p>
        Кроме того, используются стандартные журналы учёта веб-сервера (логирование) для подсчёта количества посетителей и оценки технических возможностей Сайта. 
        Эта информация используется для определения количества посетителей Сайта, 
        для разработки наиболее удобного дизайна страниц, для обеспечения соответствия Сайта используемым браузерам, 
        для создания максимально полезного содержания страниц. 
        
        Сведения по перемещениям на Сайте записываются, но не об отдельных посетителях Сайта, 
        так что никакая конкретная информация относительно Вас лично не будет сохраняться или использоваться Администрацией Сайта без Вашего согласия.
      </p>

      <p>
        Чтобы просматривать материал без cookies, Вы можете настроить свой браузер таким образом, чтобы он не принимал их либо уведомлял Вас об их использовании. 
        В любой момент Вы можете удалить cookies в настройках Вашего браузера.
      </p>

      <h2>Отказ от ответственности</h2>
      
      <p>
        Помните, передача информации личного характера при посещении сторонних сайтов, включая сайты компаний-партнёров,
        даже если веб-сайт содержит ссылку на Сайт или на Сайте есть ссылка на эти веб-сайты, не подпадает под действия данного документа. 
        
        Администрация Сайта не несёт ответственности за действия других веб-сайтов. 
        Процесс сбора и передачи информации личного характера при посещении этих сайтов регламентируется документом «Защита информации личного характера» или
        аналогичным, расположенном на сайтах этих компаний.
      </p>

      <p>
        Администрация Сайта оставляет за собой право изменять этот документ по собственному усмотрению.
        Все внесённые изменения вступают в силу после объявления о них на Сайте.
      </p>
    </Container>
  </main>
)
}

export default withLayout(
  PolicyPage,
  "Чистка сажевых фильтров в Санкт-Петербурге",
  "Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
  